.img-outreach-event {
    border-radius: 0px 0px 10px 0px;
    height: 490px;
    width: 45%;
    padding: 0;
    background-position: center;
    background-image: url('../../../images/outreach/bgc_speaker_event.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    transition: all 0.1s ease;
}

  .img-bgc-event {
    background-image: url('../../../images/outreach/bgc_speaker_event.jpg');
  }

  .img-first-robotics-event {
    background-image: url('../../../images/outreach/bgc_speaker_event.jpg');
  }

  .img-princeton-library-event {
    background-image: url('../../../images/outreach/bgc_speaker_event.jpg');
  }

  .img-camden-library-event {
    background-image: url('../../../images/outreach/bgc_speaker_event.jpg');
  }

  .outreach-logo-bgc {
    border-radius: 8px 8px 0px 0px;
    height: 100px;
    width: 100%;
    padding: 0;
    margin: 0;
    background-position: center;
    background-image: url('../../../images/outreach/black-girls-code.avif');
    background-repeat: no-repeat;
    background-size: min(280px, 7vw);
    float: left;
    clear: both;
    transition: all 0.1s ease;
  }
  
  .outreach-logo-bgc:hover {
    background-size: min(290px, 8vw);
    transition: all 0.1s ease;
  }
  
  .outreach-logo-bgc-dark {
    background-color: rgba(255, 143, 0, 0.7);
  }
  
  .outreach-logo-bgc-light {
    background-color: rgba(0, 112, 255, 0.3);
    filter: invert(1);
  }