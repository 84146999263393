/* ------------------------------------------------------------------------- */
/*                                 SPACERS                                   */
/* ------------------------------------------------------------------------- */

.divider {
  flex-basis: 100%;
  height: 0;
}

.spacer {
  flex-basis: 100%;
  height: 10px;
}

.section {
  flex-basis: 100%;
  height: 20px;
}

/* ------------------------------------------------------------------------- */
/*                                  TIMELINE                                 */
/* ------------------------------------------------------------------------- */
  
  .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline-ruler {
    content: '';
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* Container around content */
  .timeline-container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    box-sizing: border-box;
    display: flex;
  }

  .timeline-leftarrow {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 13px 0 13px 13px;
    border-color: transparent transparent transparent black;
  }

  .timeline-leftarrow::before {
    content: " ";
    height: 0;
    position: absolute;
    left: -14px;
    top: -10px;
    width: 0;
    z-index: 1;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }

  .timeline-rightarrow {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 13px 13px 13px 0;
    border-color: transparent black transparent transparent;
  }

  .timeline-rightarrow::before {
    content: " ";
    height: 0;
    position: absolute;
    top: -10px;
    left: 4px;
    width: 0;
    z-index: 1;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  .timeline-leftcircle {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    border-width: 4px;
    border-style: solid;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }

  .timeline-rightcircle {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    border-width: 4px;
    border-style: solid;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    left: -16px;
  }
  
  /* Place the container to the left */
  .timeline-container:nth-child(even) {
    left: 0%;
    justify-content: flex-end;
  }
  
  /* Place the container to the right */
  .timeline-container:nth-child(odd) {
    left: 50%;
    justify-content: flex-start;
  }
  
  .timeline-content {
    font-family: 'Century Gothic';
    font-size: 20px;
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 6px;
    border: 3px solid black;
    hyphens: auto;
  }

/* ------------------------------------------------------------------------- */
/*                                 GENERAL                                   */
/* ------------------------------------------------------------------------- */
  
.team-blurb {
    width: calc(var(--projects-desktop-container) - 8px);
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    border-radius: 30px 30px 30px 30px;
    border-style: solid;
    border-width: 4px;
    background-color: black;
}
  
.blurb-text {
    font-family: 'Century Gothic';
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    hyphens: auto;
}
  
.blurb-title {
    font-family: 'Century Gothic';
    text-align: center;
    color: white;
    font-size: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
  
.blurb-carousel {
    height: calc((var(--projects-desktop-container) - 8px) / var(--projects-carousel-ratio) * 0.7);
    width: calc((var(--projects-desktop-container) - 8px) * 0.7);
    background-color: black;
    border-style: solid;
    border-width: 4px;
    line-height: calc((var(--projects-desktop-container) - 8px) / var(--projects-carousel-ratio) * 0.7);
    text-align: center;
    overflow: hidden;
}
  
.blurb-carousel img {
    vertical-align: middle;
}
  
.blurb-carousel video {
    vertical-align: middle;
}
  
.blurb-dot {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin: 10px 5px 0px 5px;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.blurb-caption {
  color: white;
  font-family: 'Century Gothic';
  hyphens: auto;
  font-size: 20px;
  line-height: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  transition: transform 0.6s ease;
}

.blurb-carousel:hover .blurb-caption{
  transform: translateY(-100%);
}

/* ------------------------------------------------------------------------- */
/*                                  MOBILE                                   */
/* ------------------------------------------------------------------------- */
  
  @media screen and (max-width: 960px) {

    .team-blurb {
      width: calc(var(--projects-mobile-container) - 8px);
    }
  
    .blurb-carousel {
      height: calc((var(--projects-mobile-container) - 8px) / var(--projects-carousel-ratio) * 0.95);
      width: calc((var(--projects-mobile-container) - 8px) * 0.95);
      line-height: calc((var(--projects-mobile-container) - 8px) / var(--projects-carousel-ratio) * 0.95);
    }

    .blurb-dot {
      height: 40px;
      width: 40px;
      margin: 20px 10px 0px 10px;
  }
  
  }

/* ------------------------------------------------------------------------- */
/*                         TINY MOBILE TIMELINE                              */
/* ------------------------------------------------------------------------- */
  
  @media screen and (max-width: 600px) {
      /* The actual timeline (the vertical ruler) */
      .timeline-ruler {
        left: 26px;
      }
      
      /* Container around content */
      .timeline-container {
        width: 100%;
        padding-left: 60px;
        padding-right: 10px;
      }
    
      .timeline-leftarrow {
        left: 50px;
        border: medium solid white;
        border-width: 13px 13px 13px 0;
        border-color: transparent black transparent transparent;
      }
    
      .timeline-leftarrow::before {
        left: 4px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
      }
    
      .timeline-rightarrow {
        left: 50px;
      }
    
      .timeline-leftcircle {
        left: 10px;
      }
    
      .timeline-rightcircle {
        left: 10px;
      }

      .timeline-container:nth-child(even) {
        justify-content: flex-start;
      }

      .timeline-container:nth-child(odd) {
        left: 0%;
      }

      .timeline-content {
        padding: 10px 20px;
      }
    }