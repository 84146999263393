/* Gallery Page Styles */
.gallery-page {
  font-family: 'Century Gothic', sans-serif;
}

.gallery {
  font-family: 'Century Gothic', sans-serif;
}

.gallery-image:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.ReactGridGallery > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactGridGallery_tile {
  margin: 8px;
}

/* Gallery Upload Page Styles */
.gallery-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(to right, #555 0%, #000 100%);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.gallery-title {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  color: orange;
  font-family: 'Century Gothic', sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.gallery-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

/* Project dropdown styling */
.project-select {
  padding: 0.8rem;
  padding-left: 1.2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
  font-family: 'Century Gothic', sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
}

.project-select:focus {
  outline: none;
  border-color: orangered;
  background-color: rgba(255, 255, 255, 0.15);
}

.project-select option {
  background-color: #222;
  color: #fff;
}

.form-input {
  padding: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
  font-family: 'Century Gothic', sans-serif;
  transition: all 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: orangered;
  background-color: rgba(255, 255, 255, 0.15);
}

.form-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.checkbox-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.checkbox-input {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: orangered;
}

.checkbox-text {
  color: #fff;
  font-size: 1rem;
  font-family: 'Century Gothic', sans-serif;
}

.file-input {
  padding: 1rem;
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-input:hover {
  border-color: orangered;
  background-color: rgba(255, 255, 255, 0.1);
}

.file-input::-webkit-file-upload-button {
  background-color: orangered;
  color: white;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'Century Gothic', sans-serif;
  transition: background-color 0.3s ease;
}

.file-input::-webkit-file-upload-button:hover {
  border-color: orange;
}

.submit-button {
  width: 100%;
  padding-block: 10px;
  height: 40px;
  background-color: orangered;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Century Gothic', sans-serif;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.submit-button:active {
  transform: translateY(0) scale(0.98);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
