.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: 'Century Gothic';
}

.btn--dark {
  --primary: #fff;
  color: #fff;
}

.btn--light {
  --primary: #000;
  color: #000;
}

/* ------------------------------------------------------------------------- */

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
  letter-spacing: 1px;
}

.btn--outline {
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-size: 1.3rem;
  letter-spacing: 1px;
}

/* ------------------------------------------------------------------------- */

.btn--medium, .btn--medium--colored {
  padding: 8px 20px;
  font-size: 1.3rem;
  letter-spacing: 1px;
  transition: all 0.5s ease;
}

.btn--large, .btn--large--colored {
  padding: 12px 26px;
  font-size: 1.3rem;
  transition: all 0.5s ease;
}

.btn--medium--colored, .btn--large--colored {
  background-color: orangered;
  color:#2f2f2f;
  transition: all 0.5s ease;
}

.btn--medium:hover, .btn--large:hover {
  background-color: orange;
  color:#2f2f2f;
}

/* ------------------------------------------------------------------------- */

.gap-right {
  margin-right: 40px;
}