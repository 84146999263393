.about-bg-img {
  background-image: url('../../../images/about/bg_about.png');
  background-size: 400px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  height: 100%;
  z-index: -1;
}

.about-bg-img-dark {
  opacity: 0.07;
  filter: contrast(0);
  transition: 0.5s;
}

.about-bg-img-light {
  opacity: 0.04;
  filter: brightness(0);
  transition: 0.5s;
}

/* ------------------------------------------------------------------------- */

.centered-heading {
  padding-top: 0px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 2.5rem;
  line-height: 150%;
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #888;
}

/* ------------------------------------------------------------------------- */

.centered-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

/* ------------------------------------------------------------------------- */

@media screen and (max-width: 400px) {

  .centered-heading {
    font-size: 2rem;
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* ------------------------------------------------------------------------- */

@media screen and (max-width: 350px) {

  .centered-heading {
    font-size: 1.7rem;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* ------------------------------------------------------------------------- */
.year-button {
  border: none;
  border: 1.5px solid;
}
.year-button:hover {
  border-bottom-color: coral;
  filter: opacity(1);
  transition: 0.3s;
}

.year-button.active {
  border-color: coral;
}

.year-button-dark:hover {
  /* border-color: orange; */
  transition: 0.4s;
}

.year-button-light:hover {
  border-color: coral;
  transition: 0.4s;
}
