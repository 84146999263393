/* ------------------------------------------------------------------------- */
/*                                  DESKTOP                                  */
/* ------------------------------------------------------------------------- */

/* Assumes article-centered wrapping */
.team-logo-original {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    width: calc(var(--projects-desktop-container) / var(--projects-original-ratio) * var(--projects-desktop-size));
    height: calc(var(--projects-desktop-container) / var(--projects-original-ratio) * var(--projects-desktop-size));
}
  
/* This button selected, so maximize this button */
.team-logo-selected {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    width: calc(var(--projects-desktop-container) / var(--projects-selected-ratio) * var(--projects-desktop-size));
    height: calc(var(--projects-desktop-container) / var(--projects-selected-ratio) * var(--projects-desktop-size));
}
  
/* Another button selected, so minimize this button */
.team-logo-unselected {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    width: calc(var(--projects-desktop-container) / var(--projects-unselected-ratio) * var(--projects-desktop-size));
    height: calc(var(--projects-desktop-container) / var(--projects-unselected-ratio) * var(--projects-desktop-size));
}
  
.team-text-original {
    cursor: pointer;
    font-family: 'Century Gothic';
    font-size: calc(var(--projects-desktop-container) / var(--projects-original-ratio) * var(--projects-desktop-size) / var(--projects-text-ratio));
    width: calc(var(--projects-desktop-container) / var(--projects-original-ratio) * var(--projects-desktop-size));
    overflow: hidden;
}
  
.team-text-selected {
    cursor: pointer;
    font-family: 'Century Gothic';
    font-size: calc(var(--projects-desktop-container) / var(--projects-selected-ratio) * var(--projects-desktop-size) / var(--projects-text-ratio));
    width: calc(var(--projects-desktop-container) / var(--projects-selected-ratio) * var(--projects-desktop-size));
    overflow: hidden;
}
  
.team-text-unselected {
    cursor: pointer;
    font-family: 'Century Gothic';
    font-size: calc(var(--projects-desktop-container) / var(--projects-unselected-ratio) * var(--projects-desktop-size) / var(--projects-text-ratio));
    width: calc(var(--projects-desktop-container) / var(--projects-unselected-ratio) * var(--projects-desktop-size));
    overflow: hidden;
}

/* ------------------------------------------------------------------------- */
/*                                  MOBILE                                   */
/* ------------------------------------------------------------------------- */

@media screen and (max-width: 960px) {
    /* Assumes article-centered wrapping */
    .team-logo-original {
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;
        width: calc(var(--projects-mobile-container) / var(--projects-original-ratio) * var(--projects-mobile-size));
        height: calc(var(--projects-mobile-container) / var(--projects-original-ratio) * var(--projects-mobile-size));
    }
    
    /* This button selected, so maximize this button */
    .team-logo-selected {
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;
        width: calc(var(--projects-mobile-container) / var(--projects-selected-ratio) * var(--projects-mobile-size));
        height: calc(var(--projects-mobile-container) / var(--projects-selected-ratio) * var(--projects-mobile-size));
    }
    
    /* Another button selected, so minimize this button */
    .team-logo-unselected {
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;
        width: calc(var(--projects-mobile-container) / var(--projects-unselected-ratio) * var(--projects-mobile-size));
        height: calc(var(--projects-mobile-container) / var(--projects-unselected-ratio) * var(--projects-mobile-size));
    }
    
    .team-text-original {
        cursor: pointer;
        font-family: 'Century Gothic';
        font-size: calc(var(--projects-mobile-container) / var(--projects-original-ratio) * var(--projects-mobile-size) / var(--projects-text-ratio));
        width: calc(var(--projects-mobile-container) / var(--projects-original-ratio) * var(--projects-mobile-size));
        overflow: hidden;
    }
    
    .team-text-selected {
        cursor: pointer;
        font-family: 'Century Gothic';
        font-size: calc(var(--projects-mobile-container) / var(--projects-selected-ratio) * var(--projects-mobile-size) / var(--projects-text-ratio));
        width: calc(var(--projects-mobile-container) / var(--projects-selected-ratio) * var(--projects-mobile-size));
        overflow: hidden;
    }
    
    .team-text-unselected {
        cursor: pointer;
        font-family: 'Century Gothic';
        font-size: calc(var(--projects-mobile-container) / var(--projects-unselected-ratio) * var(--projects-mobile-size) / var(--projects-text-ratio));
        width: calc(var(--projects-mobile-container) / var(--projects-unselected-ratio) * var(--projects-mobile-size));
        overflow: hidden;
    }
}