.footer-container {
  position: flex;
  margin: 0;
  padding-bottom: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container-dark {
  background: linear-gradient(to bottom, #222 0%, #050505 100%);
}

.footer-container-light {
  background: #bbb;
  margin-top: 25px;
}

/* ------------------------------------------------------------------------- */

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
}

.social-media-wrap-dark {
  margin: 40px auto 0 auto;
}

.social-media-wrap-light {
  margin: 15px auto 0 auto;
}

.social-media-wrap .footer-tooltip {
  color: black;
  padding: 10px;
  background-color: coral;
  font-family: 'Century Gothic';
  font-size: 1.2rem;
}

/* ------------------------------------------------------------------------- */

.footer-logo-princeton {
  background-image: url(../../images/footer/princeton_logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 20px;
  width: 160px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  float:left;
}

.footer-logo-princeton-dark {
  filter: grayscale(1) brightness(80%);
}

.footer-logo-princeton-light {
  filter: brightness(20%);
}

.footer-logo-princeton-dark:hover {
  filter: brightness(50%) sepia(1) hue-rotate(-36deg) saturate(300%);
  transition: 0.2s all ease-out;
}

.footer-logo-princeton-light:hover {
  filter: brightness(30%) sepia(1) hue-rotate(-10deg) saturate(700%);
  transition: 0.2s all ease-out;
}

/* ------------------------------------------------------------------------- */

.footer-logo-odus {
  background-image: url(../../images/footer/odus_logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 20px;
  width: 90px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  float:left;
}

.footer-logo-odus:hover {
  filter: brightness(70%) sepia(1) hue-rotate(-30deg) saturate(300%);
  transition: 0.2s all ease-out;
}

.footer-logo-odus-dark {
  filter: grayscale(1);
}

.footer-logo-odus-light {
  filter: brightness(25%);
}

/* ------------------------------------------------------------------------- */

.footer-text {
  text-align: center;
  white-space: wrap;
  line-height: 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  font-family: 'Century Gothic';
  transition: color 1s ease-out;
  float: right;
}

.footer-text-dark { 
  color: #aaa; 
}

.footer-text-light { 
  color: #222; 
}

/* ------------------------------------------------------------------------- */

.social-media {
  max-width: 1000px;
  width: 100%;
}

/* ------------------------------------------------------------------------- */

.social-icons {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 135px;
}

/* ------------------------------------------------------------------------- */

.social-icon-link {
  font-size: 1.5rem;
}

.social-icon-link-dark {
  color: #fff;
}

.social-icon-link-light  {
  color: #222;
}

.social-icon-link-dark:hover {
  color: coral;
}

.social-icon-link-light:hover {
  color: coral;
  filter: brightness(80%);
}

/* ------------------------------------------------------------------------- */

@media screen and (max-width: 960px) {
  
  /* Stack containers in footer */
  .social-media-wrap {
    flex-direction: column;
  }
}